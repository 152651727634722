export function TabBar() {
  return (
    <div className="grid grid-cols-2 gap-1 flex items-center text-center pb-2">
      <MultilineTab
        lines={["Deine mobile", "LOYALTY Karte"]}
        isSelected={true}
      />
      <LogoAndTextTab text="Pay" />
    </div>
  );
}

function MultilineTab(props: { lines: string[]; isSelected?: boolean }) {
  const configuration = tabConfiguration(props.isSelected);

  const linesAsHTML = { __html: props.lines.join("<br />") };

  return (
    <div
      className={`${configuration.backgroundColor} ${configuration.textColor} rounded-t-2xl align-middle p-3`}
      dangerouslySetInnerHTML={linesAsHTML}
    />
  );
}

interface TabConfiguration {
  backgroundColor: string;
  textColor: string;
  logoBorderColor: string;
  logoCircleFillBackgroundColor: string;
}

function tabConfiguration(isSelected?: boolean): TabConfiguration {
  if (isSelected ?? false) {
    return {
      backgroundColor: "bg-white",
      textColor: "text-dark-blue",
      logoBorderColor: "border-dark-blue",
      logoCircleFillBackgroundColor: "bg-dark-blue",
    };
  } else {
    return {
      backgroundColor: "bg-dark-blue",
      textColor: "text-white",
      logoBorderColor: "border-white",
      logoCircleFillBackgroundColor: "bg-white",
    };
  }
}

function LogoAndTextTab(props: { text: string; isSelected?: boolean }) {
  const configuration = tabConfiguration(props.isSelected);

  return (
    <div
      className={`rounded-t-2xl h-full p-3 ${configuration.backgroundColor} ${configuration.textColor} $ grid grid-cols-3 gap-2`}
    >
      <Logo
        borderColor={configuration.logoBorderColor}
        circleFillBackgroundColor={configuration.logoCircleFillBackgroundColor}
      />
      <div className={`uppercase text-4xl font-bold`}>{props.text}</div>
    </div>
  );
}

function Logo(props: {
  borderColor: string;
  circleFillBackgroundColor: string;
}) {
  return (
    <div
      className={`border w-8 h-8 mt-1 p-1 ${props.borderColor} rounded grid grid-cols-4 gap-0.5`}
    >
      {[...Array(16)].map((_, index) => (
        <div
          key={index}
          className={`${props.circleFillBackgroundColor} w-1 h-1 rounded`}
        />
      ))}
    </div>
  );
}
