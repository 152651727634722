import { randomNumber } from "./randomNumber";

export function generateRandomEAN13() {
  const firstTwelveDigits = randomNumber(12).toString();

  return firstTwelveDigits + checkDigit(firstTwelveDigits);
}

export function checkDigit(s: string) {
  let result = 0;
  let i = 1;
  for (let counter = s.length - 1; counter >= 0; counter--) {
    result = result + parseInt(s.charAt(counter)) * (1 + 2 * (i % 2));
    i++;
  }
  return (10 - (result % 10)) % 10;
}
