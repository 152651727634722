import { ReactNode } from "react";

interface Props {
  background: ReactNode;
  children: ReactNode;
}

export function BlurryBackground(props: Props) {
  return (
    <>
      <div className="blur">{props.background}</div>
      <div className="absolute top-0 flex h-screen w-screen">
        {props.children}
      </div>
    </>
  );
}
