import { ChevronUpIcon } from "@heroicons/react/24/solid";

interface Props {
  className?: string;
}

export function BottomSheet(props: Props) {
  return (
    <div
      className={`bg-white rounded-2xl drop-shadow text-center ${props.className}`}
    >
      <ChevronUpIcon className="h-6 text-gray-400 m-auto" />
      <div className="text-4xl text-dark-blue">Punkte einlösen</div>
      <div className="mt-4 text-black">Jetzt einrichten</div>
    </div>
  );
}
