import { SignalIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { ReactNode, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BlurryBackground } from "../BlurryBackground";
import Button from "../Button";
import { BluedotsPage } from "./BluedotsPage";

type PageState = "choose" | "submitting" | "done";

export function VoteCodePage() {
  const { code } = useParams();
  const [pageState, setPageState] = useState<PageState>("choose");
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate("/");
  };

  const submitVote = async function (usedSuccessfully: boolean) {
    setPageState("submitting");

    const rawResponse = await fetch(
      `${process.env.REACT_APP_API_BASE_URL || ""}/api/codes/${code}/vote`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ usedSuccessfully: usedSuccessfully }),
      }
    );

    setPageState("done");
  };

  let dialog: ReactNode;
  switch (pageState) {
    case "choose":
      dialog = (
        <VotingDialog
          code={code}
          submitVote={submitVote}
          navigateHome={navigateHome}
        />
      );
      break;
    case "submitting":
      dialog = (
        <LoadingDialog title="Please wait" body="Submitting your vote..." />
      );
      break;
    case "done":
      dialog = <SuccessDialog navigateHome={navigateHome} />;
      break;
  }

  return (
    <BlurryBackground background={<BluedotsPage />}>{dialog}</BlurryBackground>
  );
}

function VotingDialog(props: {
  code?: string;
  submitVote: (usedSuccessfully: boolean) => void;
  navigateHome: () => void;
}) {
  return (
    <Dialog title="Did the code work for you?">
      <code className="text-center block bg-gray-100 dark:bg-gray-900 p-2 mb-2 underline decoration-1 decoration-dashed decoration-black-100 underline-offset-4">
        {props.code}
      </code>

      <ul>
        <li>
          <Button mood="positive" onClick={() => props.submitVote(true)}>
            Yes, it worked
          </Button>
        </li>
        <li>
          <div className="h-1">{/* Spacer */}</div>
        </li>
        <li>
          <Button mood="negative" onClick={() => props.submitVote(false)}>
            No, it did <i>not</i> work
          </Button>
        </li>
        <li>
          <div className="h-6">{/* Spacer */}</div>
        </li>
        <li>
          <Button onClick={props.navigateHome}>I did not use the code</Button>
        </li>
      </ul>
    </Dialog>
  );
}

function LoadingDialog(props: { title: string; body: string }) {
  return (
    <Dialog title={props.title}>
      <div className="grid grid-cols-1 content-center">
        <SignalIcon className="animate-spin h-10 mx-auto" />
        <p className="text-center">{props.body}</p>
      </div>
    </Dialog>
  );
}

function SuccessDialog(props: { navigateHome: () => void }) {
  return (
    <Dialog title="Vote submitted">
      <div className="grid grid-cols-1 gap-y-2 content-center">
        <CheckCircleIcon className="h-10 mx-auto fill-green-500" />
        <p className="text-center">Thank you for your contribution!</p>
        <Button onClick={props.navigateHome}>Back to the codes</Button>
      </div>
    </Dialog>
  );
}

function Dialog(props: { title: string; children: ReactNode }) {
  return (
    <div className="my-auto mx-8 bg-gray-200 dark:bg-stone-700 border-gray-500 dark:border-stone-900 w-full p-4 border-2 rounded drop-shadow">
      <h2 className="text-xl font-bold mb-4">{props.title}</h2>
      {props.children}
    </div>
  );
}
