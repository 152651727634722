import { MouseEventHandler } from "react";
import { BottomSheet } from "./BottomSheet";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { TabBar } from "./TabBar";
import { BarcodeWithLabel } from "./BarcodeWithLabel";

interface Props {
  barcodeText: string;
  customerId: string;
  onCancel?: () => void;
}

export function BlueDotsCard(props: Props) {
  return (
    <div className="bg-light-blue h-screen flex flex-col justify-between">
      <CloseButton className="mb-4 pt-2" onClick={props.onCancel} />
      <div className="rounded-3xl drop-shadow-xl mx-10">
        <TabBar />
        <div className="bg-white rounded-2xl -top-5 relative py-32 grid place-items-center">
          <BarcodeWithLabel text={props.barcodeText} />
          <div className="font-thin text-sm">Kundennummer: {props.customerId}</div>
        </div>
      </div>
      <BottomSheet className="grow w-screen" />
    </div>
  );
}

export function BlueDotsCardWithRandomData() {
  return <BlueDotsCard barcodeText="123456789012" customerId="1 987 654 321" />;
}

function CloseButton(props: {
  className: string;
  onClick?: MouseEventHandler;
}) {
  return (
    <div
      className={`pr-4 cursor-pointer ${props.className}`}
      onClick={props.onClick}
    >
      <div className="bg-dark-gray rounded-full h-7 w-7 p-1 float-right">
        <XMarkIcon className="text-white" />
      </div>
      <div className="clear-both" />
    </div>
  );
}
