import { useEffect } from "react";
import bwipjs from "bwip-js";

interface Props {
  text: string;
}

export function BarcodeWithLabel(props: Props) {
  useEffect(() => {
    try {
      bwipjs.toCanvas("barcode-canvas", {
        bcid: "ean13",
        text: props.text,
        scale: 2,
        height: 36,
      });
    } catch (e) {
      console.log(e);
    }
  }, [props]);

  return (
    <div className="self-center">
      <canvas id="barcode-canvas"></canvas>
      <div className="bg-white mx-4 rounded-t-md py-1 -mt-6 relative text-center text-black">
        {props.text}
      </div>
    </div>
  );
}
