import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import {
  BluedotsEditPage,
  BluedotsPage,
  ErrorPage,
  ChooseCodePage,
} from "./components/pages";
import { VoteCodePage } from "./components/pages/VoteCodePage";
import { Code } from "./interfaces";

function App() {
  const [codesFromBackend, setCodesFromBackend] = useState<Code[]>([]);

  const getData = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL || ""}/api/codes`)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        setCodesFromBackend(json);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <ChooseCodePage codesFromBackend={codesFromBackend} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/edit",
      element: <BluedotsEditPage />,
    },
    {
      path: "/:barcodeText/edit",
      element: <BluedotsEditPage />,
    },
    {
      path: "/:code/vote",
      element: <VoteCodePage />,
    },
    {
      path: "/:barcodeText",
      element: <BluedotsPage />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
