import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Code } from "../../interfaces";
import "./ChooseCodePage.css";

export function ChooseCodePage(props: { codesFromBackend: Code[] }) {
  const listEntries = props.codesFromBackend.map(ListItem);

  return (
    <div className="page">
      <List title="Your codes">
        <Link to="/edit">
          Click here
        </Link>{" "}
        to manually enter a code.
      </List>

      <hr />

      <List title="Codes from others">
        <ul>{listEntries}</ul>
      </List>
    </div>
  );
}

function List(props: { title: string; children?: ReactNode }) {
  return (
    <>
      <h2>{props.title}</h2>
      <ul>{props.children}</ul>
    </>
  );
}

function ListItem(props: { code: string; source: string }) {
  return (
    <li key={props.code}>
      <Link to={props.code}>{props.code}</Link>
      <br />
      <div className="source">
        Source: {props.source}
      </div>
    </li>
  );
}
