import { useRouteError, isRouteErrorResponse } from "react-router-dom";

export function ErrorPage() {
  const error: Error = useRouteError() as Error;
  console.error(error);

  if (isRouteErrorResponse(error)) {
    return (
      <ErrorPageComponent
        title="Oops!"
        message="Sorry, an unexpected error has occurred."
        code={`${error.status} ${error.statusText || error.message}`}
      />
    );
  } else {
    return (
      <ErrorPageComponent
        title="Well, fudge"
        message="This is not a router error response. So... what is it?"
      />
    );
  }
}

function ErrorPageComponent(props: {
  title: string;
  message: string;
  code?: string;
}) {
  return (
    <div className="flex h-screen w-screen">
      <div className="my-auto mx-8 bg-red-200 border-red-500 w-full p-4 border-2 rounded">
        <h1>{props.title}</h1>
        <p>{props.message}</p>

        {props.code && <code>{props.code}</code>}
      </div>
    </div>
  );
}
