import { ReactNode } from "react";

export default function Button(props: {
  children: ReactNode;
  onClick: () => void;
  mood?: "positive" | "negative" | "default";
}) {
  const mood = props.mood ?? "default";

  let backgroundColor: string;
  switch (mood) {
    case "default":
      backgroundColor = "bg-blue-100 dark:bg-blue-900";
      break;
    case "negative":
      backgroundColor = "bg-red-300 dark:bg-red-900";
      break;
    case "positive":
      backgroundColor = "bg-green-300 dark:bg-green-900";
      break;
  }

  return (
    <a
      onClick={props.onClick}
      className={`block p-1 text-center border border-black rounded cursor-pointer ${backgroundColor}`}
    >
      {props.children}
    </a>
  );
}
