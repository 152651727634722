import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  checkDigit as calculateCheckDigitForEAN13,
  generateRandomEAN13,
} from "../../helpers/generateRandomEAN13";
import { BlueDotsCardWithRandomData } from "../BlueDotsCard";
import { BlurryBackground } from "../BlurryBackground";

export function BluedotsEditPage() {
  // EAN13 consists of twelwe numbers and a check digit, making up a string of 13 characters.
  // The `numberOfCharacters` is the amount of characters that the user can enter.
  const numberOfCharacters = 12;
  const { barcodeText = generateRandomEAN13() } = useParams();
  const [enteredBarcode, setEnteredBarcode] = useState(
    barcodeText.substring(0, numberOfCharacters)
  );
  const [checkDigit, setCheckDigit] = useState<number | null>(null);

  useEffect(() => {
    if (
      enteredBarcode.length !== numberOfCharacters ||
      isNaN(+enteredBarcode)
    ) {
      setCheckDigit(null);
    } else {
      setCheckDigit(calculateCheckDigitForEAN13(enteredBarcode));
    }
  }, [enteredBarcode]);

  return (
    <BlurryBackground background={<BlueDotsCardWithRandomData />}>
      <div className="my-auto mx-8 bg-gray-200 border-gray-500 w-full p-4 border-2 rounded">
        <div>
          <input
            type="text"
            autoFocus={true}
            maxLength={numberOfCharacters}
            value={enteredBarcode}
            onChange={(e) => setEnteredBarcode(e.target.value)}
          />
          <span className="ml-4">
            {enteredBarcode.length}/{numberOfCharacters}
          </span>
        </div>
        {checkDigit && (
          <>
            <div>Check digit: {checkDigit}</div>

            <Link to={`/${enteredBarcode}${checkDigit}`}>Let's go!</Link>
          </>
        )}
      </div>
    </BlurryBackground>
  );
}
