import { useNavigate, useParams } from "react-router-dom";
import { generateRandomCustomerId } from "../../helpers/generateRandomCustomerId";
import { generateRandomEAN13 } from "../../helpers/generateRandomEAN13";
import { BlueDotsCard } from "../BlueDotsCard";

export function BluedotsPage() {
  const { barcodeText = generateRandomEAN13() } = useParams();
  const navigate = useNavigate();

  return (
    <BlueDotsCard
      barcodeText={barcodeText}
      customerId={generateRandomCustomerId()}
      onCancel={() => navigate(`/${barcodeText}/vote`)}
    />
  );
}
